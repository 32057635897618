$inner-width: 605px;

.course-promo--seven {
  .promo-seven {
    max-width: 996px;
    margin: 0 auto;

    .inner {
      max-width: $inner-width;
      position: relative;
      padding: 0 24px;
    }

    .app-screen {
      position: absolute;
      top: 0;

      &--android {
        left: 100%;
        transform: translateX(-48px);
      }

      &--ios {
        left: 100%;
        transform: translate(100px, -24px);
      }
    }

    .course-promo__title, .course-promo__description {
      text-align: left;
      margin: 0;
    }

    .course-promo__title {
      font-family: "Open Sans", sans-serif;
      font-size: 44px;
      font-weight: 100;
      line-height: 1.2;

      @media (max-width: 425px) {
        font-size: 36px;
      }
    }

    .course-promo__description {
      font-size: 18px;
      font-weight: bold;
      margin-top: 46px;

      @media (max-width: 425px) {
        margin-top: 32px;
        font-size: 16px;
      }
    }
  }
}

.section-seven-description {
  .inner {
    max-width: 996px;
    margin: 0 auto;

    .content {
      max-width: $inner-width;
      padding: 0 24px;
    }

    h3 {
      font-family: "Open Sans", sans-serif;
      color: #212121;
      font-size: 24px;
      line-height: 33px;
    }

    .badges {
      text-align: center;

      a {
        width: 40%;
        display: inline-block;
        box-sizing: border-box;

        @media (max-width: 425px) {
          width: 100%;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  .stat {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 32px 0;

    &__circle-wrapper {
      flex-grow: 1;
      flex-shrink: 1;
      max-width: 25%;

      @media (max-width: 425px) {
        max-width: 45%;
      }

      .stat__circle {
        padding-bottom: 100%;
        width: 100%;
        background: #2c96ff;
        border-radius: 50%;
        position: relative;
        display: flex;
        text-align: center;

        & > div {
          display: flex;
          flex-direction: column;
          position: absolute;
          align-content: center;
          justify-content: center;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          color: white;

          .digit {
            font-size: 34px;
            font-family: "Open Sans", sans-serif;
            font-weight: 100;
            padding-bottom: 4px;

            @media (max-width: 425px) {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}

.progress--completed {
  .md-bar2 {
    background-color: #4CAF50;
  }
}