.cta-widget {
  position: fixed;
  top: 120px;
  z-index: 999;
  line-height: 1.1;
  text-align: center;

  width: 256px;
  right: -296px;

  padding: 8px 8px 24px 32px;
  color: white;
  background: #1976D2;

  transition: right .2s cubic-bezier(0.4, 0.0, 0.2, 1);

  &.is-visible {
    right: 0;
    transition-delay: .2s;
  }

  .btn--cta {
    background: #FF5722;
    color: #fff;
  }

  .btn--close {
    position: absolute;
    left: 6px;
    top: 6px;
  }
}

.cta-widget-closed {
  position: fixed;
  top: 80px;
  z-index: 999;
  color: white;
  background: #1976D2;
  right: -64px;
  width: 64px;
  opacity: .7;

  .mdl-button {
    width: 64px;
    height: 64px;
  }

  transition: right .2s cubic-bezier(0.4, 0.0, 0.2, 1);

  border-radius: 50% 0 0 50%;

  &.is-visible {
    right: 0;
    transition-delay: .4s;
  }
}