.section--partner {
  p {
    font-size: 16px;
  }
}

.promo--partner {
  background: #2c96ff;

  .slogan {
    color: #fff;
  }

  .sub-slogan {
    color: #BBDEFB;
  }
}

.card-partner {
  .mdl-card__title {
    background: #2c96ff;
    color: #fff;
  }
}