.language-cards {
  justify-content: center;
  align-items: stretch;
}

@mixin card--light {
  background: #ebf9ff;
}

@mixin card--btn {
  min-height: 0;
  height: auto;
  width: auto;
  font: 400 18px / 24px "PT Mono";
  text-transform: none;
  display: flex;
  box-sizing: content-box;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

@mixin card--dark {
  background: #0f94fa;
  color: white;

  a {
    color: white;
  }
}

.language-card {
  padding: 24px;
  margin: 8px;
  flex-grow: 1;
  width: 200px;

  &__title {
    font: 400 22px / 24px "PT Mono";
    text-align: center;
    margin: 0;
  }

  &__badge {
    margin: 8px auto;
  }
}

.cards-column {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.is-small-screen .language-card {
  padding: 16px;
}

.list--block {
  list-style: none;
  padding: 0;

  a {
    display: block;
    padding: 8px;
    text-decoration: none;
    border-radius: 2px;

    &:hover {
      background: rgba(255, 255, 255, .3);
    }

    .fa {
      vertical-align: middle;
      font-size: 32px;
      margin-right: 12px;
      width: 34px;
      text-align: center;
    }
  }
}

.card-grammar, .card-songs {
  @extend .language-card;
  @include card--light;
  @include card--btn;
}

.card-seven {
  @extend .language-card;
  @include card--dark;
}

.card-words {
  .list--block a {
    color: #333;

    &:hover {
      background: rgba(0, 0, 0, .3);
    }
  }
}

.card-course--popular {
  background: #484bbf;
  color: white;

  .card-course__duration {
    color: #C5CAE9;
  }

  .card-course__title, .card-course__list-label {
    color: #feb712;
  }

  .card-course__list-item {
    &:before {
      color: #feb712;
    }
  }
}

.card-course {
  &__list-item {
    line-height: 1.3;

    &:before {
      top: 0;
      content: "\f05d";
      font-family: "FontAwesome";
      background: none;
      font-size: 16px;
      color: #2196F3;
    }
  }

  @media (max-width: 839px) {
    padding: 24px;
  }
}

.card--custom {
  background: #1565C0;
  color: #E3F2FD;

  .card-course {
    &__title {
      color: #fff;
    }

    &__list-label {
      color: #fff;
    }

    &__list-item {
      &:before {
        color: white;
      }
    }
  }

  .mdl-button {
    background: #fff;
    color: #0D47A1;
  }
}

.button--green {
  font-weight: 400;
}