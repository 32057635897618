@import "language_cards";
@import "profile";
@import "content";
@import "buy_product";
@import "partner";
@import "investors";

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.text--success {
  color: #4CAF50;
}

body.login {
  overflow: scroll;

  .login-page {
    margin: auto;
  }
}

.icon--error {
  color: #F44336;
}

.gap--vertical {
  flex-grow: 1;
}

.cta-demo {
  font-weight: 600;
  color: white;
  display: inline-block;
  margin-right: 8px;

  &:hover {
    text-decoration: none;
  }
}

.cert-stub {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

.section--success {
  background: #4CAF50;
  transition: background 1s;

  p {
    color: white;
    font-size: 18px;
  }
}

.section--blue {
  background: #B3E5FC;
}

.section--dark-blue {
  background: #01579B;
}

.section-langs {
  position: relative;
  z-index: 100;
}

@import "details";

h3 {
  font-size: 24px;
  font-family: "PT Mono", sans-serif;
}

.examples--native {
  .audio-block {
    text-align: left;
  }
}

.audio-card {
  display: flex;
  flex-direction: column;

  .mdl-card__supporting-text {
    flex-grow: 1;
  }
}

.lesson__content {
  td p {
    font-size: 13px;
    line-height: 1.5;
  }
}

.about {
  .map-canvas {
    height: 600px;

    .info {
      padding: 0;
    }

    h4 {
      font: 600 14px / 24px "Open Sans";
      margin: 12px 0 4px;
      color: #666;

      &:first-child {
        margin-top: 0;
      }
    }

    p {
      font: 400 13px / 16px "Open Sans";
      margin: 0 !important;
      color: #666;
    }
  }
}

.seven__link_mini_course {
  $color-bg: #C8E6C9;
  $color-font: #1B5E20;
  $color-button: #43A047;
  $color-border: #81C784;

  p {
    text-align: center;
    font: 400 16px / 24px "Open Sans";
    color: $color-font;
  }

  .icon {
    flex-grow: 0;
    padding-right: 24px;

    .material-icons {
      color: $color-button;
      font-size: 48px;
      min-width: 48px;
      text-align: center;
      transform: scale(0);

      transition: transform .2s cubic-bezier(0.4, 0.0, 0.2, 1) .5s;

      &.inview {
        transform: scale(1);
      }
    }
  }

  .description {
    flex-grow: 1;

    .mdl-button {
      background: $color-button;
      color: white;
    }
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
  border: 1px solid $color-border;
  background: $color-bg;
  border-radius: 2px;
  padding: 24px;
}

.marathon-label {
  text-align: center;
  font-weight: bold;
}

.refund-table {
  margin: 0 auto 24px;
}

.policies-list {
  flex-flow: row wrap;
}

.custom-course-dialog {
  min-width: 50%;

  .md-toolbar-tools h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@import "cta_widget";
@import "banner_course";
@import "seven";

.mdl-mini-footer__left-section {
  display: flex;
  align-items: center;

  .policies-list {
    margin: 0;
  }
}

.social-icons__block {
  .fa {
    vertical-align: middle;
  }
}

.layout--bg {
  &-ru {
    background-image: url(../img/bg/ru.jpg);
  }

  &-jp {
    background-image: url(../img/bg/jp.jpg);
  }
}

.swiper-button-prev, .swiper-button-next {
  background: none !important;
}

@import "landing_foreign";

.sitelang-list {
  .active {
    font-weight: bold;
  }

  .fa {
    vertical-align: baseline;
  }
}

.select-lang {
  position: relative;

  &__material {
    position: absolute;
    width: 16px !important;
    height: 16px !important;
    bottom: 8px;
    right: 8px;
  }
}

.language-select-dialog {
  width: 80%;

  .my-language {
    font-size: 16px;
    font-weight: bold;
    padding: 0 24px;
  }

  md-tab-content {
    text-align: center;
  }

  .ls-button {
    text-decoration: none;
  }
}

.price-note {
  font-size: small;
  line-height: 1.4;
  text-align: right;
  margin-top: 24px;
  font-style: italic;
}

.swiper-slide {
  text-decoration: none;
}

.profile-lang-selector {
  width: 300px;
  max-width: 100%;
}

.section--dark-blue {
  p {
    color: white;
  }
}

$button-social: #213680;
$button-connected: #00897B;

.button--social {
  color: #fff;
  background: $button-social;
  margin-top: 8px;
  box-sizing: border-box;

  &:hover {
    background: darken($button-social, 10%);
  }
}

.button--connected {
  color: #fff;
  background: $button-connected;
  margin-top: 8px;

  &:hover {
    background: darken($button-connected, 10%);
  }
}

.social-form-connected {
  display: inline;
}

.social-buttons-wrapper {
  text-align: center;
  margin-bottom: 24px;
}

.messages {
  padding: 8px;
  color: white;
  background: rgb(255, 82, 82);
  border-radius: 2px;

  li {
    list-style: none;
  }
}

.words-badge {
  display: block;
  width: 70%;
  margin: 0 auto;

  img {
    max-width: 100%;
  }
}

.paid-icons {

  background: #01579B;

  &__container {
    max-width: 1000px;
    margin: 36px auto 24px;
    padding-top: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .paid-icon {
    margin: 0 0 24px;
    text-align: center;
    box-sizing: border-box;

    @media (max-width: 839px) {
      width: 45%;
    }

    @media (max-width: 479px) {
      width: calc(100% - 32px);
    }

    @media (min-width: 840px) {
      width: 30%;
    }

    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 16px;
    border-radius: 2px;

    img {
      width: 48px;
      height: 48px;
      margin-right: 16px;
    }

    div {
      flex-grow: 1;
    }

    h3 {
      font-family: "Open Sans", sans-serif;
      text-transform: uppercase;
      font-weight: 100;
      font-size: 16px;
      line-height: 1.2;
      margin: 0 0 16px;
      color: #feb600;
    }

    p {
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      color: #fff;
    }
  }
}

.course-bergen {
  width: 100%;

  .card-course__title-wrapper {
    background: #fff;
    padding-bottom: 0;

    .card-course__title {
      color: #607D8B;
    }
  }
}

.sup-link {
  font-size: small;
  vertical-align: super;
}

.button--delivery {
  height: 48px;

  &[disabled] {
    background-color: #BDBDBD !important;
    color: #616161 !important;
  }
}