.song-text {
  width: 40%;
  padding: 8px;
  box-sizing: border-box;
  border-bottom: 1px solid #f0f0f0;
  margin: 0 !important;

  &:last-of-type {
    border-bottom: none;
  }
}

.song-description {
  width: 60%;
  padding: 8px;
  box-sizing: border-box;
  border-bottom: 1px solid #f0f0f0;

  &:last-of-type {
    border-bottom: none;
  }

  p {
    font-size: 13px;
    line-height: 1.5;
    margin-bottom: 8px;
  }
}

.song-table {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}

@mixin pdf-small {
  .song-text, .song-description {
    width: 100%;
  }

  .song-text {
    border-bottom: none;
  }
}

.pdf-content {
  @include pdf-small;
}

@media (max-width: 479px) {
  @include pdf-small;
}

.render-plugin {
  .song-table {
    display: block;
  }

  @include pdf-small;
}