$accent: #2c96ff;

@import "songs";

.text-center {
  text-align: center;
}

ac {
  display: inline;
}

.hg {
  position: relative;
  display: inline-block;
  padding: 0 6px;

  &:before {
    display: block;
    content: attr(data-title);
    top: -8px;
    height: 12px;
    position: absolute;
    left: 0;
    font-size: 10px;
    font-weight: normal;
    line-height: 12px;
    color: #666;
    width: 100%;
    text-align: center;
  }
}

ac, .accent {
  font-weight: bold;
  color: $accent;
}

st {
  text-decoration: none;
  //&:after {
  //  display: inline;
  //  content: '\0301';
  //}
}

.dash {
  padding-left: 16px;
  position: relative;
}

.dash-before {
  content: '–';
  display: inline-block;
  left: 0;
  top: calc(50% - 10px);
  position: absolute;
}

.bold {
  font-weight: bold;
}

.mute, .text-muted {
  color: #999;
}

.container-content {
  max-width: 996px;
}

.center {
  text-align: center;
}

.right {
  text-align: right !important;
}

@for $i from 1 through 10 {
  .accent-#{$i} td:nth-child(#{$i}) {
    @extend .accent;
  }

  .mute-#{$i} td:nth-child(#{$i}) {
    @extend .mute;
  }

  .right-#{$i} td:nth-child(#{$i}) {
    @extend .right;
  }

  .center-#{$i} td:nth-child(#{$i}) {
    @extend .center;
  }

  .bold-#{$i} td:nth-child(#{$i}) {
    @extend .bold;
  }

  .dash-#{$i} td:nth-child(#{$i}) {
    @extend .dash;

    &:before {
      @extend .dash-before;
    }
  }
}

//.loopTable(@index, @name, @rule) when (@index > 0) {
//  .@{name}-@{index} td:nth-child(@{index}) {
//    @rule();
//  }
//
//  .loopTable(@index - 1, @name, @rule);
//}
//
//.loopTableBefore(@index, @name, @rule) when (@index > 0) {
//  .@{name}-@{index} td:nth-child(@{index}):before {
//    @rule();
//  }
//
//  .loopTableBefore(@index - 1, @name, @rule);
//}
//
//.loopTable(10, accent, @accent-text);
//.loopTable(10, mute, @muted-text);
//.loopTable(10, right, @align-right);
//.loopTable(10, center, @align-center);
//.loopTable(10, bold, @bold);
//.loopTable(10, dash, @dash);
//.loopTableBefore(10, dash, @dash-before);

.example {
  margin-left: 30px;
}

.table-borderless td {
  border: none !important;
}

.half {
  width: auto;
  min-width: 50%;
  margin: 0 auto;
}

.alert {
  padding: 12px;
  border: 1px solid;
  text-align: center !important;
  border-radius: 2px;

  &-info {
    background: #E1F5FE;
    border-color: #03A9F4;
  }

  &-hint {
    border-color: #FF9800;
    background: #FFF3E0;
  }
}

table.examples {
  td:nth-child(2) {
    color: #999;
    padding-left: 16px;
    position: relative;

    &:before {
      content: '–';
      display: inline-block;
      left: 0;
      top: calc(50% - 10px);
      position: absolute;
    }
  }
}

ol.teacher-list {
  & > li {
    list-style-type: upper-alpha;
  }

  & ol > li {
    list-style-type: decimal;
  }

  & ol ol > li {
    list-style-type: lower-alpha;
  }
}

.list-dialog {
  margin-bottom: 16px;

  li {
    list-style-type: none;
    position: relative;

    &:before {
      content: "—";
      position: absolute;
      left: -1.5em;
      top: 0;
    }
  }
}

.list-alpha {
  & > li {
    list-style-type: lower-alpha;
  }
}

.nobr {
  white-space: nowrap;
}

.example {
  table {
    margin-bottom: 8px;

    td {
      font-size: 14px;
      padding-left: 0;
    }
  }
}

.lesson__content {
  color: #424242;
  padding: 12px;

  @import "lesson_content";

  &--seven {
    padding: 4.2%;

    p,
    ol,
    ul,
    .mdl-radio__label,
    table td,
    table th {
      @media (min-width: 576px) {
        font-size: 16px;
        line-height: 30px;
      }
    }
  }
}

.course-image {
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  &__title {
    font-size: small !important;
    text-align: center !important;
    font-style: italic !important;
  }
}

.text-small {
  font-size: small;
}

.exercises__block {
  h2 {
    text-align: left;
    color: #424242;
    font: 400 18px 'PT Mono', sans-serif;
    margin: 24px 0 16px;
  }
}

.letter {
  padding: 12px;
  margin-bottom: 24px;
  border: 1px solid #E0E0E0;
  background: #FAFAFA;
  box-shadow: 0 2px 4px #E0E0E0;

  &__address {
    line-height: 1.1;
  }

  &__date {
    text-align: right;
  }

  &__hint {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: italic;
    opacity: .7;

    &--after {
      @extend .letter__hint;

      &:before {
        content: '←';
        display: inline-block;
        margin: 0 12px 0 24px;
      }
    }

    &--before {
      @extend .letter__hint;

      &:after {
        content: '→';
        display: inline-block;
        margin: 0 24px 0 12px;
      }
    }
  }
}