$main-icon-color: #2a94ff;
$primary: #2a94ff;
$bright: #FF9800;
$green: #4CAF50;
$custom: #607D8B;

.start-now-block {
  text-align: center;
}

.love-it {
  font-size: 24px;
  line-height: 36px;
}

.yes-free {
  font: 400 18px "PT Mono";
  margin-top: 36px;
  text-align: right;
}

.section--white {
  background: white;
}

.card-course {
  &__icon {
    text-align: center;
    font-size: 64px;
    line-height: 72px;
    color: $main-icon-color;
    margin-bottom: 24px;
  }

  &__duration {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-top: 16px;
  }

  .card-course__desc {
    font-size: small;
    line-height: 1;
    margin-top: 16px;
    text-align: center;
  }

  &__title-wrapper {
    border-radius: 2px 2px 0 0;
    margin: -40px -40px 0;
    padding: 40px;
    background: $main-icon-color;

    &.wrapper--bright {
      background: $bright;
    }

    &.wrapper--green {
      background: $green;
    }

    &.wrapper--custom {
      background: $custom;
    }

    .card-course__title {
      color: white;
    }

    .card-course__price {
      font: 400 32px "PT Mono";
      font-weight: bold;
      color: white;
      text-align: center;
      padding-top: 16px;
    }
  }
}

.button-bright, .button-bright:hover, .button-bright:active {
  background: $bright;
  color: white;
}

.button-custom, .button-custom:hover, .button-custom:active {
  background: $custom;
  color: white;
}

.button-primary, .button-primary:hover, .button-primary:active {
  background: $primary;
  color: white;
}

.button-green, .button-green:hover, .button-green:active {
  background: $green;
  color: white;
}

//
//$description-width: 25%;
//
//.details {
//  display: flex;
//  flex-direction: row;
//  flex-flow: wrap;
//  justify-content: space-between;
//  max-width: 940px;
//  margin-left: auto;
//  margin-right: auto;
//}
//
//.details__description {
//  width: $description-width;
//  line-height: 18px;
//  @media screen and (max-width: $phone) {
//    width: 100%;
//    text-align: center;
//  }
//}
//
//@mixin details-value {
//  flex-grow: 1;
//  text-align: center;
//  padding: 0 16px;
//  box-sizing: border-box;
//  max-width: 20%;
//  line-height: 18px;
//  @media screen and (max-width: $phone) {
//    max-width: 30%;
//  }
//
//  .fa {
//    vertical-align: bottom;
//  }
//}
//
//.details__title {
//  border-bottom: 4px solid;
//  @include details-value;
//  padding-bottom: 8px;
//
//  &--free {
//    border-bottom-color: $green;
//  }
//
//  &--basic {
//    border-bottom-color: $bright;
//  }
//
//  &--mini {
//    border-bottom-color: $primary;
//  }
//}
//
//.details__value {
//  @include details-value;
//}
//
//.description {
//  &--bold {
//    font-weight: bold;
//  }
//}

.method {
  display: flex;
  flex-direction: column;

  &__title {
    flex-grow: 1;
  }
}

.map {
  margin: 0 auto;
  width: 640px;
  max-width: 100%;
  display: block;
  background: white;
}

.faq__question {
  font: 400 24px "PT Mono";
  margin-bottom: 8px;
}

.faq__answer {
  font-size: 18px;
  padding-left: 40px;
  color: #666;
}

.map-section {
  position: relative;
  box-shadow: inset 0 4px 5px 0 rgba(0, 0, 0, .14),
  inset 0 1px 10px 0 rgba(0, 0, 0, .12),
  inset 0 2px 4px -1px rgba(0, 0, 0, .2);

  padding: 24px 12px;
  background: #fff;

  .section__title {
    text-align: center;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    width: 100%;
  }
}

#map {
  height: 480px;
  //  position: absolute;
  //  left: 0;
  //  right: 0;
  //  top: 0;
  //  bottom: 0;
  //  z-index: -1;
}

.fab--large {
  width: 128px;
  height: 128px;
  align-items: center;
  align-content: center;
  display: flex;
}