blockquote {
  font-size: 14px;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 18px;
  width: 100%;
  border-radius: 3px;

  thead {
    text-align: center;
  }

  td, th {
    padding: 8px;
    font-size: 13px;
    line-height: 1.5;
  }

  &.half {
    width: auto;
    margin: 0 auto 18px;
    min-width: 50%;
  }
}

.table-striped {
  & tbody tr:nth-child(odd) {
    background: #fcfcfc;
  }
}

.table-bordered {
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}

.table--top {
  td {
    vertical-align: top;
  }
}

h1 {
  margin-top: 32px;
  font: 400 32px "PT Mono";
  color: #2c96ff;
  text-align: left;

  &:first-child {
    margin-top: 0;
  }
}

h2 {
  text-align: center;
  font: 400 26px "PT Mono";
  color: #feb600;
}

h3 {
  text-align: center;
  font: 400 20px 'PT Mono', sans-serif;
}

h4 {
  text-align: left;
  font: 400 18px 'PT Mono', sans-serif;
}