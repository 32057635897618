banner-course-button {
  align-self: flex-start;
}

.banner-course-button {
  position: relative;
  text-align: center;
  display: block;
  background: rgb(63, 81, 181);
  padding: 12px;
  align-self: flex-start;
  border-radius: 0 0 4px 4px;

  a {
    text-decoration: none;
    color: #fff;
    outline: none;
    font-size: 24px;
  }

  @media (max-width: 767px) {
    a {
      font-size: 12px;
    }
  }
}