@import "../../../scss/_variables.scss";
@import "../../../scss/_colors.scss";

$main-icon-color: #2a94ff;
$primary: #484bbf;
$bright: #FF9800;
$green: #4CAF50;

.details {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-between;
  max-width: 940px;
  margin: 0 auto;

  &:last-child {
    .details__value {
      border-radius: 0 0 2px 2px;
    }
  }
}

.details__description {
  width: 20%;
  line-height: 18px;
  padding: 8px 0;
  font-weight: bold;

  @media screen and (max-width: $phone) {
    width: 100%;
    text-align: center;
  }
}

@mixin details-value {
  flex-grow: 1;
  text-align: center;
  box-sizing: border-box;
  //line-height: 18px;
  max-width: 20%;
  padding: 8px 0;

  .fa {
    vertical-align: bottom;
  }
}

.details__title {
  border-bottom: 4px solid;
  @include details-value;
  padding-bottom: 8px;
  border-radius: 2px 2px 0 0;
  font-weight: bold;

  overflow: hidden;
  text-overflow: ellipsis;

  a {
    text-decoration: none;
    color: inherit;
    font-weight: bold;
  }

  &.width--30 {
    max-width: 25%;
  }

  &.width--25 {
    max-width: 18%;
  }

  &.width--50 {
    max-width: 30%;
  }

  @media screen and (max-width: $phone) {
    &.width--30 {
      max-width: 30%;
    }

    &.width--25 {
      max-width: 24%;
    }

    &.width--50 {
      max-width: 30%;
    }
  }

  &--free {
    border-bottom-color: $primary;
    color: $primary;
  }

  &--basic {
    border-bottom-color: #FB8C00;
    background: $bright;
    color: white;
  }

  &--mini {
    border-bottom-color: $green;
    color: $green;
  }

  &--marathon {
    border-bottom-color: #009688;
    color: #009688;
  }
}

.details__value {
  @include details-value;

  .md-button {
    background-color: rgba(158, 158, 158, 0.2);

    span {
      font-weight: bold;
    }
  }

  &--basic {
    border-bottom-color: $bright;
    background: $bright;
    color: white;
  }

  &.width--30 {
    max-width: 25%;
  }

  &.width--25 {
    max-width: 18%;
  }

  &.width--50 {
    max-width: 30%;
  }

  @media screen and (max-width: $phone) {
    &.width--30 {
      max-width: 30%;
    }

    &.width--25 {
      max-width: 24%;
    }

    &.width--50 {
      max-width: 30%;
    }
  }
}

.course-details-btn {
  text-align: center;
  margin-top: 16px;
}

.translation {
  font-style: italic;
}

.list--dialog {
  li {
    list-style-type: '–';
  }
}