.investors {
  font-family: sans-serif;

  &__intro {
    p {
      padding: 0 12px;
      margin: 12px 0;
      font-size: 16px;

      &:first-child {
        margin-top: 24px;
      }
    }
  }

  .investors__form {
    width: 500px;
    margin: 20px auto;
    padding: 30px;
    text-align: center;
    font-size: 1.2em;

    > p {
      font-size: 0.85em;
    }
    > button {
      padding: 0 10px;
    }
  }

  .investors__list {
    padding: 20px;
  }
}

.investor {
  margin-top: 40px;
  padding: 10px 10px;

  &__form {
    md-checkbox {
      flex-shrink: 0;
    }
  }

  > h1 {
    text-align: center;
  }

  .investor__profile {
    text-align: center;

    > img, i {
      font-size: 72px;
      border-radius: 100px;
      border: solid 5px lightskyblue;
    }

    i {
      padding: 14px;
    }

    > p {
      margin-top: 10px;
      font-weight: bold;
      font-size: 18px;
    }
  }
  .investor__review {
    padding-bottom: 20px;

    .investor__review__input {
      &[readonly="readonly"] {
        border: none;
      }
    }
  }
  .investor__review_border-left {
    padding-left: 10px;
    border-left: solid 1px lightskyblue;
  }
  .investor__review_border-right {
    padding-right: 10px;
    margin-right: 5px;
    border-right: solid 1px lightskyblue;
  }
  .investor__investments {
    > p {
      font-size: 20px;
      font-weight: lighter;
    }
    > ul {
      padding-left: 20px;

      a {
        color: lightskyblue;
      }
    }
  }
}

.next-investors {
  margin: auto;
}

.save-investor {
  text-align: right;
}

.md-select_inline-block {
  display: inline-block;
}

.investor-share {
  margin-top: 36px;
  text-align: center;
  margin-bottom: 24px;
}