.card-profile__content {
  margin-top: 125px;
  padding: 16px;

  .mdl-fieldset-center {
    margin: auto;
    width: 610px;
  }

  .mdl-textfield__label {
    top: 20px;
  }

  .mdl-textfield--floating-label.is-focused .mdl-textfield__label, .mdl-textfield--floating-label.is-dirty .mdl-textfield__label {
    top: 0;
  }

  .mdl-textfield__error {
    margin-top: 0;
  }

  .thumb {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border: 4px solid #fff;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-125px, -125px);
    cursor: pointer;
  }

  .mdl-textfield.invalid {
    .mdl-textfield__error {
      visibility: visible;
    }

    .mdl-textfield__input {
      border-color: #de3226;
      box-shadow: none;
    }

    .mdl-textfield__label {
      color: #de3226;
      font-size: 12px;
    }
  }
}

#card-profile {
  overflow: visible;
  margin-top: 125px;
}

@media all and (max-width: 700px) {
  #card-profile {
    margin-top: 100px;
  }

  .card-profile__content {
     margin-top: 100px;

     .mdl-fieldset-center {
       margin: auto;
       width: 300px;
     }

     .thumb {
       width: 200px;
       height: 200px;
       transform: translate(-100px, -100px);
     }
  }
}
